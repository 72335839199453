export default function locale(code) {
  return locales[code] || locales[DEFAULT_CODE]
}

const DEFAULT_CODE = 'pt'

const locales = {
  pt: {
    search: 'Pesquisar...',
    status: {
      any: 'Qualquer status',
      free: 'Grátis',
      active: 'Ativo',
      inactive: 'Inativo'
    },
    franchise: {
      any: 'Qualquer tipo',
      not: 'Não franqueado',
      common: 'Afiliado Comum',
      pro: 'Afiliado Pro',
      ambassador: 'Embaixador',
      concessionaire: 'Concessionário'
    }
  },
  en: {
    search: 'Search...',
    status: {
      any: 'Any status',
      free: 'Free',
      active: 'Active',
      inactive: 'Inactive'
    },
    franchise: {
      any: 'Any type',
      not: 'No franchisee',
      common: 'Common Affiliate',
      pro: 'Affiliate Pro',
      ambassador: 'Ambassador',
      concessionaire: 'Concessionaire'
    }
  }
}