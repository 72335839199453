import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { FixedSizeList } from 'react-window'
import translationsFrom from './util/locale'
import './App.sass'

function App() {
  const navbarRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({
    franchise: '',
    status: '',
    search: ''
  })
  const [translations, setTranslations] = useState(translationsFrom('en'))

  const [leads, setLeads] = useState([])
  const [path, setPath] = useState([])
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    setAuth(parseToken())
  }, [])

  useEffect(() => {
    if (auth) {
      const { user: { id, name, locale } } = auth
      setTranslations(translationsFrom(locale))
      findLeads({ id, name })
    }
  }, [auth])

  async function findLeads(user) {
    try {
      setIsLoading(true)

      const { data } = await axios.get(`/user/${user.id}/leads`, {
        headers: {
          'Authorization': auth?.token
        }
      })

      let index = Number.MAX_SAFE_INTEGER
      for (let i = 0; i < path.length; i++) {
        if (path[i].id == user.id) {
          index = i
          break
        }
      }

      setFilter({
        franchise: '',
        status: '',
        search: ''
      })
      setPath([...path.filter((_, i) => i < index), user])
      setLeads(data)

    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  function parseToken() {
    const token = window.location.hash.substring(1)
    const [_, payload] = token.split('.')
    return { user: JSON.parse(atob(payload)), token }
  }

  const filtered = leads.filter(l => {
    const { franchise, status, search } = filter
    if (franchise != '' && l.franchiseType != franchise) return false
    if (status != '' && l.paymentStatus != status) return false
    if (search != '' && String(l.name).toLowerCase().indexOf(search.toLowerCase()) == -1) return false
    return true
  })

  function transform(list) {
    const listOfLists = []
    const columnsCount = ~~(window.innerWidth / 140)

    let row = []

    for (let i = 0; i < list.length; i++) {
      row.push(list[i])

      if (row.length >= columnsCount || row.length >= list.length || i == list.length - 1) {
        listOfLists.push([...row])
        row = []
      }
    }

    return listOfLists
  }
  const filteredGroup = transform(filtered)

  const navbarHeight = navbarRef.current?.getBoundingClientRect().height || 0

  return (
    <div className={`App ${isLoading ? 'is-loading' : ''}`}>
      <div className="navbar" ref={navbarRef}>
        <div className="path">
          {path.map((v, i) => (
            <div className="item" key={i} onClick={() => i + 1 < path.length && findLeads(v)}>
              <div className="name" title={String(v.name).trim()}>{String(v.name).trim()}</div>
            </div>
          ))}
        </div>
        <div className="filter">
          <input className="search" type="text" placeholder={translations.search} value={filter.search} onChange={e => setFilter({ ...filter, search: e.target.value })} />
          <select className="status" value={filter.status} onChange={e => setFilter({ ...filter, status: e.target.value })}>
            <option value="">{translations.status.any}</option>
            <option value="free">{translations.status.free}</option>
            <option value="active">{translations.status.active}</option>
            <option value="inactive">{translations.status.inactive}</option>
          </select>
          <select className="franchise" value={filter.franchise} onChange={e => setFilter({ ...filter, franchise: e.target.value })}>
            <option value="">{translations.franchise.any}</option>
            <option value="-">{translations.franchise.not}</option>
            <option value="common">{translations.franchise.common}</option>
            <option value="pro">{translations.franchise.pro}</option>
            <option value="ambassador">{translations.franchise.ambassador}</option>
            <option value="concessionaire">{translations.franchise.concessionaire}</option>
          </select>
        </div>
      </div>
      {
        filtered.length > 0 ?
          <FixedSizeList
            height={window.innerHeight - navbarHeight}
            itemSize={140}
            itemCount={filteredGroup.length}>
            {({ index, style }) => {
              const r = filteredGroup[index]
              return (
                <div style={style} className="list">
                  {r.map(l => (
                    <div className="item" onClick={() => findLeads(l)} key={l.id}>
                      <div className="photo" style={{ backgroundImage: `url(https://www.gravatar.com/avatar/${l.photo}?s=64&d=mp` }}>
                        <div className={`status ${l.paymentStatus}`}>{l.paymentStatus}</div>
                      </div>
                      <div className="name" title={String(l.name).trim()}>{String(l.name).trim()}</div>
                      <div className="info">{translations.franchise[l.franchiseType] || l.franchiseType}</div>
                    </div>
                  ))}
                </div>
              )
            }}
          </FixedSizeList> : <div className="list-empty" />
      }
    </div>
  )
}

export default App